.media-gallery {
  background-color: rgba(255, 103, 103, 0.897);
  display: flex;
  flex-direction: column;  /* stack items vertically */
  align-items: center;  /* align items to the center */
  width: 100%;  /* Full width */
}


.image-container, .video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;  /* Distributes items evenly */
}


/* Fixed sizes for images */
.gallery-image {
  width: 650px;  /* Fixed width */
  height: 400px;  /* Fixed height */
  margin: 80px; /* Space around each video */
  border-radius: 20px; /* Rounded corners */
}

.gallery-video {
  width: 650px;  /* Fixed width */
  height: auto;  /* Auto height */
  position: relative;
  margin: 50px;
  border-radius: 20px;
  z-index: 20;
}

/* Fixed sizes for vertical videos */
.vertical-video {
  width: 350px;  /* Fixed width */
  height: auto;  /* Auto height */
  margin: 50; /* Space around each video */
  border-radius: 20px; /* Rounded corners */
}

.audio-toggle-button {
  position: fixed;  /* Fixed position */
  bottom: 20px;     /* 20px from the bottom */
  right: 20px;      /* 20px from the right */
  z-index: 1000;    /* Overlap other elements */
}

/* Media query for phone */
@media screen and (max-width: 480px) {
  .gallery-image, .gallery-video, .vertical-video {
    object-fit: contain;
    width: 100%;
    height: 100vh;  /* Full viewport height */
    margin: 0;
    border-radius: 0;
  }
  /* If you want to keep aspect ratio for videos */
  .gallery-video {
    object-fit: contain;
  }
}