* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.research,
.projects,
.my-cv {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.research {
  /* background-image: url('../public/images/img-1.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  /* background-image: url('../public/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;  /* or fill, as you prefer */
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
}

.journey {
  /* background-image: url('../public/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.kitesurfing {
  /* background-image: url('../public/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


/* Mobile-friendly styles */
/* Mobile-friendly styles */


@media screen and (max-width: 768px) {
  .hero-container > h2 {
    display: none;  /* Hide the welcome message */
  }
  /* ... other styles for small screens ... */
}