.journey-cards {
  padding: 4rem;
  background: hsla(19, 100%, 65%, 0.795);
}

.tech-stack-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;  /* Center the items */
  gap: 16px;
}

.tech-stack-item {
  flex: 0 0 auto;
  margin: 10px;
  text-align: center;
}

.tech-stack-logo {
  max-width: 130px;  /* Or any size you prefer */
  height: auto;
  margin-bottom: 10px;
}


.center-block {
  text-align: center;
}

.journey-info {
  flex: 1;
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 16px;
}

.journey-info-text {
  flex: 1;
}

.journey-info-time {
  flex: 0;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.journey-info-date {
  margin-bottom: auto; /* This pushes the next item to the bottom */
  white-space: nowrap;
}

.journey-info-location {
  margin-top: auto; /* This will go to the bottom */
  white-space: nowrap;
}
.journey-title {
  text-align: center;
}


.journey-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.journey-container {
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.journey-wrapper {
  margin: 50px 0 45px;
}

.journey-item {
  display: flex;
  align-items: stretch;  /* Make sure all children stretch to the same height */
}

.journey-image {
  flex: 0;
  margin-right: 16px;
  text-align: center;
  width: 200px;
}


.journey-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.journey-info {
  flex: 1;
  max-width: 70%;
  margin: auto;
}

.journey-info h2 {
  margin-bottom: 10px;
}

.journey-info span {
  display: block;
  margin-bottom: 15px;
  font-size: 0.9em;
  color: #ccc;
}

.flag-icon {
  height: 16px;  /* You can adjust as needed */
  width: auto;
  margin-left: 5px;
}

.journey-image h3 {
  font-size: 2em;  /* Make the text bigger */
}


/* Media query for mobile */
@media screen and (max-width: 480px) {
  .journey-cards {
    padding: 1rem; /* reduced padding for more width */
  }

  .journey-container {
    width: 100%; /* use full width */
  }

  .tech-stack-container {
    justify-content: space-around;  /* Distributes items evenly */
  }
  
  .tech-stack-logo {
    width: 30px; /* reduced size for mobile */
  }
  
  .tech-stack-text {
    font-size: 14px; /* reduced font-size for mobile */
  }
  .journey-item {
    flex-direction: column;
    align-items: center;
  }
  .journey-image, .journey-info {
    width: 100%;
    max-width: 100%;
  }
  .journey-image {
    margin-right: 0;
    margin-bottom: 16px;
  }
}