video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -10;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  align-items: center;
  z-index: 1;
  text-shadow: 0 0 4px #f0ebeb; 
  overflow-x: hidden;
}

.hero-container > h2 {
  color: #fff;
  font-size: 40px;
  align-items: center;
  position: relative;  /* Add this line */
  text-shadow: 0 0 10px #000; 
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 24px;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  z-index: 1;
  text-shadow: 0 0 10px #000; 
  flex-direction: column-reverse;
}

/* General Button improvements for mobile touch */
.hero-btns .btn {
  margin: 12px;  /* Increased margin */
  padding: 12px;  /* Added padding */
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
  z-index: 1;
}


.right-circle-img {
  border-radius: 50%;
  width: 300px;  /* Adjust as needed */
  height: 300px;  /* Adjust as needed */
  margin: auto;  /* Center the image */
  z-index: 3;  /* Adjust z-index to be above other elements */
}

.left-circle-img {
  left: 100px;
}

.right-circle-img {
  right: 100px;
}

.scroll-down-arrow {
  color: #f0ebeb;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 58px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-20px);
  }
  60% {
    transform: translateX(-50%) translateY(-10px);
  }
}

@media screen and (max-width: 480px) {
  .footer-link-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .hero-container > h1 {
    font-size: 40px;  /* Smaller font size */
  }
  .hero-container > h2 {
    font-size: 30px;  /* Smaller font size */
  }
  .hero-container > p {
    font-size: 18px;  /* Smaller font size */
  }
  .footer-link-items {
    align-items: center;
    text-align: center;
  }
  .social-icons {
    justify-content: center;
  }
  .social-media-wrap,
  .website-rights {
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  video {
    width: 90%;  /* reduce to 90% of the screen width */
    height: auto;  /* maintain aspect ratio */
    position: relative;  /* change from fixed to relative */
    margin: auto;  /* center the video */
  }
  .right-circle-img {
    width: 200px;
    height: 200px;
    margin-bottom: 50px;  /* Add extra space at the top */
  }
  .scroll-down-arrow {
    display: none;
  }
}

