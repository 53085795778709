video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  align-items: center;
  z-index: 1;
  text-shadow: 0 0 4px #f0ebeb; 
}

.hero-container > h2 {
  color: #fff;
  font-size: 40px;
  align-items: center;
  position: relative;  /* Add this line */
  text-shadow: 0 0 10px #000; 
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 24px;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  z-index: 1;
  text-shadow: 0 0 10px #000; 
}

.hero-btns {
  margin-top: 32px;
  z-index: 1;
  text-shadow: 0 0 10px #000; 
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
  z-index: 1;
}

.scroll-down-arrow {
  color: #f0ebeb;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  animation: bounce 2s infinite;
}

.scroll-instruction {
  font-size: 24px;  /* Larger text */
  font-weight: bold;  /* Make it bold */
  color: #3498db;  /* Text color */
  text-shadow: 2px 2px 4px #000000;  /* Text shadow for better visibility */
  margin-top: 20px;  /* Space from the top */
  margin-bottom: 20px;  /* Space from the bottom */
}

.center-text {
  text-align: center;
}


@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  video {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    width: 80%;  /* Less than 100% */
    height: 80%;  /* Less than 100% */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 15px;
    align-items: center;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

