.info-section {
  background-color: rgba(127, 255, 212, 0.726);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;  /* Added padding for better spacing */
}

.info-section:nth-child(even) {
  flex-direction: row;
}

.info-img {
  max-width: 100%;  /* Full width on small screens */
  height: auto;
}

.info-text {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;  /* Smaller text for small screens */
  padding: 10px;  /* Added padding for better spacing */
}

/* Media query for larger screens */
@media screen and (min-width: 768px) {
  .info-img {
    max-width: 600px;
  }

  .info-text {
    font-size: 25px;
  }
}
